import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "offcanvas offcanvas-end right-sheet",
  tabindex: "-1",
  id: "offcanvasRightA",
  "aria-labelledby": "offcanvasRightLabelA"
}
const _hoisted_3 = { class: "offcanvas-header border-bottom py-4 d-block" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = {
  type: "button",
  class: "bg-white me-2",
  "data-bs-dismiss": "offcanvas",
  "aria-label": "Close"
}
const _hoisted_6 = { class: "border p-2 mt-4 rounded" }
const _hoisted_7 = { class: "d-flex justify-content-between align-items-center mb-3 fs-14" }
const _hoisted_8 = { class: "d-flex justify-content-between align-items-center fs-14" }
const _hoisted_9 = { class: "offcanvas-body" }
const _hoisted_10 = { class: "meter-token my-3 rounded" }
const _hoisted_11 = { class: "d-flex p-3 align-items-center justify-content-between" }
const _hoisted_12 = { class: "met-no" }
const _hoisted_13 = { class: "card-wrapper border rounded" }
const _hoisted_14 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_15 = { class: "provider-logo p-2 rounded" }
const _hoisted_16 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_17 = { class: "p-text" }
const _hoisted_18 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_19 = { class: "p-text" }
const _hoisted_20 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_21 = { class: "p-text" }
const _hoisted_22 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_23 = { class: "p-text" }
const _hoisted_24 = { class: "d-grid mt-4" }
const _hoisted_25 = {
  class: "offcanvas offcanvas-bottom full-size",
  tabindex: "-1",
  id: "offcanvasBottomC",
  "aria-labelledby": "offcanvasBottomLabelC"
}
const _hoisted_26 = { class: "offcanvas-header border-bottom py-4 d-block" }
const _hoisted_27 = { class: "border p-2 mt-4 rounded" }
const _hoisted_28 = { class: "d-flex justify-content-between align-items-center mb-3 fs-14" }
const _hoisted_29 = { class: "d-flex justify-content-between align-items-center fs-14" }
const _hoisted_30 = { class: "offcanvas-body" }
const _hoisted_31 = { class: "meter-token my-3 rounded" }
const _hoisted_32 = { class: "d-flex p-3 align-items-center justify-content-between" }
const _hoisted_33 = { class: "met-no" }
const _hoisted_34 = { class: "card-wrapper border rounded" }
const _hoisted_35 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_36 = { class: "provider-logo p-2 rounded" }
const _hoisted_37 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_38 = { class: "p-text" }
const _hoisted_39 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_40 = { class: "p-text" }
const _hoisted_41 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_42 = { class: "p-text" }
const _hoisted_43 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_44 = { class: "p-text" }
const _hoisted_45 = { class: "d-grid mt-4" }

import { computed } from "vue";
import { useStore } from "vuex";
import { formatDateTime } from "@/core/utils/helpers";
import { useToast } from "vue-toast-notification";



export default /*@__PURE__*/_defineComponent({
  __name: 'EachTransaction',
  props: {
  transaction: {},
},
  setup(__props) {

const store = useStore();

const props = __props;
const trans: any = computed(() => {
  return props.transaction
});

const makePayment = () => {
  store
    .dispatch("post", {
      endpoint: "/purchase",
      details: {
        beneficiary: false,
        meter_no: trans.value.meter_no,
        provider: trans.value.provider,
        meter_type: trans.value.meter_type,
        phone: trans.value.phone,
        amount: trans.value.amount,
        meter_name: trans.value.meter_name,
      },
    })
    .then((resp) => {
      useToast().success("Processing");
      window.location.href = resp.data.data;
    })
    .catch((err) => {
  })
};

return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!

  return (trans.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", _hoisted_5, [
                _createVNode(_component_Iconify, { icon: "ep:back" })
              ]),
              _cache[2] || (_cache[2] = _createElementVNode("span", null, "Back", -1))
            ]),
            _cache[5] || (_cache[5] = _createElementVNode("h5", { id: "offcanvasRightLabelA" }, "Transactions Details", -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[3] || (_cache[3] = _createElementVNode("p", null, "Reference", -1)),
                _createElementVNode("p", null, _toDisplayString(trans.value.ref), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[4] || (_cache[4] = _createElementVNode("p", null, "Paid At", -1)),
                _createElementVNode("p", null, _toDisplayString(_unref(formatDateTime)(trans.value.created_at)), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _cache[6] || (_cache[6] = _createElementVNode("p", { class: "meter p-text" }, "Meter Token", -1)),
                _createElementVNode("p", _hoisted_12, _toDisplayString(trans.value.token), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _cache[7] || (_cache[7] = _createElementVNode("p", { class: "text-gray p-text" }, "Provider", -1)),
                _createElementVNode("div", _hoisted_15, _toDisplayString(trans.value.provider), 1)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _cache[8] || (_cache[8] = _createElementVNode("p", { class: "text-gray p-text" }, "Meter Type", -1)),
                _createElementVNode("p", _hoisted_17, _toDisplayString(trans.value.meter_type), 1)
              ]),
              _createElementVNode("div", _hoisted_18, [
                _cache[9] || (_cache[9] = _createElementVNode("p", { class: "text-gray p-text" }, "Meter Number", -1)),
                _createElementVNode("p", _hoisted_19, _toDisplayString(trans.value.meter_no), 1)
              ]),
              _createElementVNode("div", _hoisted_20, [
                _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-gray p-text" }, "Meter Name", -1)),
                _createElementVNode("p", _hoisted_21, _toDisplayString(trans.value.meter_name), 1)
              ]),
              _createElementVNode("div", _hoisted_22, [
                _cache[11] || (_cache[11] = _createElementVNode("p", { class: "text-gray p-text" }, "Amount", -1)),
                _createElementVNode("p", _hoisted_23, "NGN " + _toDisplayString(trans.value.amount), 1)
              ]),
              _cache[12] || (_cache[12] = _createElementVNode("div", { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }, [
                _createElementVNode("p", { class: "text-gray p-text" }, "Fee"),
                _createElementVNode("p", { class: "p-text" }, "NGN 100")
              ], -1))
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (makePayment()), ["prevent"])),
                class: "btn-primary border-0"
              }, _cache[13] || (_cache[13] = [
                _createElementVNode("span", { class: "fw-bold" }, "Purchase Again", -1)
              ]))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "d-flex align-items-center justify-content-between" }, [
              _createElementVNode("h5", { id: "offcanvasBottomLabelC" }, "Transactions Details"),
              _createElementVNode("div", { class: "close-btn me-4" }, [
                _createElementVNode("button", {
                  type: "button",
                  class: "bg-white btn-close",
                  "data-bs-dismiss": "offcanvas",
                  "aria-label": "Close"
                })
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _cache[14] || (_cache[14] = _createElementVNode("p", null, "Reference", -1)),
                _createElementVNode("p", null, _toDisplayString(trans.value.ref), 1)
              ]),
              _createElementVNode("div", _hoisted_29, [
                _cache[15] || (_cache[15] = _createElementVNode("p", null, "Paid At", -1)),
                _createElementVNode("p", null, _toDisplayString(_unref(formatDateTime)(trans.value.created_at)), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("div", _hoisted_32, [
                _cache[17] || (_cache[17] = _createElementVNode("p", { class: "meter p-text" }, "Meter Token", -1)),
                _createElementVNode("p", _hoisted_33, _toDisplayString(trans.value.token), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_34, [
              _createElementVNode("div", _hoisted_35, [
                _cache[18] || (_cache[18] = _createElementVNode("p", { class: "text-gray p-text" }, "Provider", -1)),
                _createElementVNode("div", _hoisted_36, _toDisplayString(trans.value.provider), 1)
              ]),
              _createElementVNode("div", _hoisted_37, [
                _cache[19] || (_cache[19] = _createElementVNode("p", { class: "text-gray p-text" }, "Meter Type", -1)),
                _createElementVNode("p", _hoisted_38, _toDisplayString(trans.value.meter_type), 1)
              ]),
              _createElementVNode("div", _hoisted_39, [
                _cache[20] || (_cache[20] = _createElementVNode("p", { class: "text-gray p-text" }, "Meter Number", -1)),
                _createElementVNode("p", _hoisted_40, _toDisplayString(trans.value.meter_no), 1)
              ]),
              _createElementVNode("div", _hoisted_41, [
                _cache[21] || (_cache[21] = _createElementVNode("p", { class: "text-gray p-text" }, "Meter Name", -1)),
                _createElementVNode("p", _hoisted_42, _toDisplayString(trans.value.meter_name), 1)
              ]),
              _createElementVNode("div", _hoisted_43, [
                _cache[22] || (_cache[22] = _createElementVNode("p", { class: "text-gray p-text" }, "Amount", -1)),
                _createElementVNode("p", _hoisted_44, "NGN " + _toDisplayString(trans.value.amount), 1)
              ]),
              _cache[23] || (_cache[23] = _createElementVNode("div", { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }, [
                _createElementVNode("p", { class: "text-gray p-text" }, "Fee"),
                _createElementVNode("p", { class: "p-text" }, "NGN 100")
              ], -1))
            ]),
            _createElementVNode("div", _hoisted_45, [
              _createElementVNode("button", {
                onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (makePayment()), ["prevent"])),
                class: "btn-primary border-0"
              }, _cache[24] || (_cache[24] = [
                _createElementVNode("span", { class: "fw-bold" }, "Purchase Again", -1)
              ]))
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})