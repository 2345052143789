import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "exampleModal",
  "aria-hidden": "true",
  "aria-labelledby": "exampleModalToggleLabel",
  tabindex: "-1"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content px-5 py-4" }
const _hoisted_4 = { class: "modal-header border-0 p-0 d-flex justify-content-between align-items-center" }
const _hoisted_5 = {
  type: "button",
  "data-bs-dismiss": "modal",
  "aria-label": "Close",
  style: {"height":"27px","width":"27px","border-radius":"50%","background-color":"black","color":"white"}
}
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "d-flex mt-5 justify-content-center align-items-center" }
const _hoisted_8 = { class: "d-grid ms-5" }

import {ref} from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'Return',
  emits: ["purchaseHistory"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const continuePurchase = () => {
emit("purchaseHistory");
}



return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "" }, [
            _createElementVNode("h1", {
              class: "modal-title fs-5",
              id: "exampleModalToggleLabel"
            }, " Cancel Order? ")
          ], -1)),
          _createElementVNode("button", _hoisted_5, [
            _createVNode(_component_Iconify, {
              icon: "iconamoon:close-duotone",
              style: {"font-size":"18px"}
            })
          ])
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-gray" }, "Are you sure you want to cancel this order?", -1)),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_router_link, { to: "/buy-energy-app" }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("div", { class: "d-grid" }, [
                  _createElementVNode("button", { class: "btn-normal px-4 text-primary" }, "Yes, Cancel")
                ], -1)
              ])),
              _: 1
            }),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (continuePurchase()), ["prevent"])),
                class: "btn-primary px-4 text-primary"
              }, "No, Continue")
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})