import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, vModelCheckbox as _vModelCheckbox } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "step2-wrapper mt-4 d-flex justify-content-center"
}
const _hoisted_5 = { class: "summary-page" }
const _hoisted_6 = { class: "card-wrapper border rounded" }
const _hoisted_7 = { class: "provider px-3 py-1 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_8 = { class: "provider-logo rounded p-2" }
const _hoisted_9 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_10 = { class: "p-text" }
const _hoisted_11 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_12 = { class: "p-text" }
const _hoisted_13 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_14 = { class: "p-text" }
const _hoisted_15 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_16 = { class: "p-text" }
const _hoisted_17 = { class: "provider p-3 d-flex justify-content-between align-items-center" }
const _hoisted_18 = { class: "p-text" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-6" }
const _hoisted_21 = { class: "d-grid mt-4" }
const _hoisted_22 = { class: "col-6" }
const _hoisted_23 = { class: "desktop-screen" }
const _hoisted_24 = { class: "d-grid mt-4" }
const _hoisted_25 = { class: "mobile-screen" }
const _hoisted_26 = { class: "d-grid mt-4" }
const _hoisted_27 = { key: 1 }
const _hoisted_28 = { class: "row" }
const _hoisted_29 = { class: "col-md-6 col-sm-12" }
const _hoisted_30 = { class: "each-field mb-3" }
const _hoisted_31 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_32 = {
  key: 0,
  class: "my-4"
}
const _hoisted_33 = { class: "mobile-screen" }
const _hoisted_34 = {
  "data-bs-toggle": "offcanvas",
  "data-bs-target": "#offcanvasBottom",
  "aria-controls": "offcanvasBottom",
  class: "d-flex align-items-center text-gray"
}
const _hoisted_35 = { class: "desktop-screen" }
const _hoisted_36 = {
  "data-bs-toggle": "offcanvas",
  "data-bs-target": "#offcanvasRight",
  "aria-controls": "offcanvasRight",
  class: "d-flex align-items-center text-gray"
}
const _hoisted_37 = { class: "col-md-6 col-sm-12" }
const _hoisted_38 = { class: "each-field mb-3" }
const _hoisted_39 = { class: "input-group mb-3" }
const _hoisted_40 = {
  class: "input-group-text",
  id: "inputGroup-sizing-default"
}
const _hoisted_41 = ["value"]
const _hoisted_42 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_43 = { class: "row" }
const _hoisted_44 = { class: "col-md-6 col-sm-12" }
const _hoisted_45 = { class: "each-field mb-3" }
const _hoisted_46 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_47 = { class: "col-md-6 col-sm-12" }
const _hoisted_48 = { class: "each-field mb-3" }
const _hoisted_49 = { class: "input-group" }
const _hoisted_50 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_51 = { class: "row" }
const _hoisted_52 = { class: "col-md-6 col-sm-12" }
const _hoisted_53 = { class: "each-field mb-3" }
const _hoisted_54 = { class: "input-group" }
const _hoisted_55 = {
  class: "input-group-text",
  id: "basic-addon1"
}
const _hoisted_56 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_57 = { class: "form-btn mt-3" }
const _hoisted_58 = ["disabled"]
const _hoisted_59 = {
  key: 1,
  class: "form"
}
const _hoisted_60 = { class: "row" }
const _hoisted_61 = { class: "col-md-6 col-sm-12" }
const _hoisted_62 = { class: "each-field mb-3" }
const _hoisted_63 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_64 = {
  key: 0,
  class: "my-4"
}
const _hoisted_65 = { class: "mobile-screen" }
const _hoisted_66 = {
  "data-bs-toggle": "offcanvas",
  "data-bs-target": "#offcanvasBottom",
  "aria-controls": "offcanvasBottom",
  class: "d-flex align-items-center text-gray"
}
const _hoisted_67 = { class: "desktop-screen" }
const _hoisted_68 = {
  "data-bs-toggle": "offcanvas",
  "data-bs-target": "#offcanvasRight",
  "aria-controls": "offcanvasRight",
  class: "d-flex align-items-center text-gray"
}
const _hoisted_69 = { class: "col-md-6 col-sm-12" }
const _hoisted_70 = { class: "each-field mb-3" }
const _hoisted_71 = { class: "input-group mb-3" }
const _hoisted_72 = {
  class: "input-group-text",
  id: "inputGroup-sizing-default"
}
const _hoisted_73 = ["value"]
const _hoisted_74 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_75 = { class: "row" }
const _hoisted_76 = { class: "col-md-6 col-sm-12" }
const _hoisted_77 = { class: "each-field mb-3" }
const _hoisted_78 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_79 = { class: "col-md-6 col-sm-12" }
const _hoisted_80 = { class: "each-field mb-3" }
const _hoisted_81 = { class: "input-group" }
const _hoisted_82 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_83 = { class: "row" }
const _hoisted_84 = { class: "col-md-6 col-sm-12" }
const _hoisted_85 = { class: "each-field mb-3" }
const _hoisted_86 = { class: "input-group" }
const _hoisted_87 = {
  class: "input-group-text",
  id: "basic-addon1"
}
const _hoisted_88 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_89 = {
  key: 0,
  class: "form-btn mt-4"
}
const _hoisted_90 = ["disabled"]
const _hoisted_91 = {
  key: 1,
  class: "form-btn mt-3"
}
const _hoisted_92 = ["disabled"]
const _hoisted_93 = {
  key: 2,
  class: "step2-wrapper mt-3 d-flex justify-content-center"
}
const _hoisted_94 = { class: "summary-page" }
const _hoisted_95 = { class: "d-flex justify-content-between align-items-center mb-3 border-bottom py-3" }
const _hoisted_96 = { class: "d-flex align-items-center" }
const _hoisted_97 = { class: "card-wrapper border rounded" }
const _hoisted_98 = { class: "provider px-3 py-1 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_99 = { class: "provider-logo p-2 rounded" }
const _hoisted_100 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_101 = { class: "p-text" }
const _hoisted_102 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_103 = { class: "p-text" }
const _hoisted_104 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_105 = { class: "p-text" }
const _hoisted_106 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_107 = { class: "p-text" }
const _hoisted_108 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_109 = { class: "p-text" }
const _hoisted_110 = { class: "d-flex justify-content-between p-3 align-items-center mt-3 border-primary-light rounded" }
const _hoisted_111 = { class: "p-text" }
const _hoisted_112 = { class: "desktop-screen" }
const _hoisted_113 = { class: "d-grid mt-4" }
const _hoisted_114 = { class: "mobile-screen" }
const _hoisted_115 = { class: "d-grid mt-4" }
const _hoisted_116 = {
  key: 3,
  class: "step2-wrapper mt-4 d-flex justify-content-center"
}
const _hoisted_117 = { class: "summary-page" }
const _hoisted_118 = { class: "d-flex justify-content-between align-items-center mb-3 border-bottom py-3" }
const _hoisted_119 = { class: "d-flex align-items-center" }
const _hoisted_120 = {
  class: "back-icon mr-2",
  "data-bs-target": "#exampleModal",
  "data-bs-toggle": "modal"
}
const _hoisted_121 = { class: "card-wraper border rounded" }
const _hoisted_122 = { class: "provider px-3 py-1 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_123 = { class: "provider-logo rounded p-2" }
const _hoisted_124 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_125 = { class: "p-text" }
const _hoisted_126 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_127 = { class: "p-text" }
const _hoisted_128 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_129 = { class: "p-text" }
const _hoisted_130 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_131 = { class: "p-text" }
const _hoisted_132 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_133 = { class: "p-text" }
const _hoisted_134 = { class: "d-flex justify-content-between p-3 align-items-center mt-3 border-primary-light rounded" }
const _hoisted_135 = { class: "p-text" }
const _hoisted_136 = { class: "mt-3 bg-gray rounded-pill p-3" }
const _hoisted_137 = { class: "form-check form-switch" }
const _hoisted_138 = { class: "d-grid mt-4" }
const _hoisted_139 = ["disabled"]
const _hoisted_140 = { class: "fw-bold" }

import { ref, reactive, onMounted, computed } from "vue";
import CreateAccount from "@/components/Modal/CreateAccount.vue";
import Return from "@/components/Modal/Return.vue";
import CreateAccountMOB from "@/components/BottomSheet/CreateAccount/CreateAccountMOB.vue";
import Deskbeneficiary from "@/components/BottomSheet/Beneficiary/SaveAsBeneficiary.vue";

import History from "@/components/BottomSheet/RecentTransaction.vue";

import { useRouter } from "vue-router";
import { formatAmount } from "@/core/utils/helpers";
import { useStore } from "vuex";
import { useToast } from "vue-toast-notification";


export default /*@__PURE__*/_defineComponent({
  __name: 'Purchase',
  setup(__props) {

const store = useStore();
const summaryPage = ref(false);
const purchaseSummary = ref(false);
const router = useRouter();
const loading = ref(false);
const displayForm = ref(true);
const recentTransaction = ref(false);
const loadInfo = ref(false);
const providers: any = ref([]);

const saveAsBeneficiary = ref(false);
const transaction: any = ref({});
const phoneRegex = /^0(7[0]|8[0-1]|9[0-1])[0-9]{8}$/;

const apiResponse = reactive({
  Customer_Name: "",
});

const errors = ref({
  meter_no: false,
  provider: false,
  meter_type: false,
  amount: false,
  phone: false,
  email: false,
});

const eMsg = ref({
  meter_no: " This field is required",
  provider: "This field is required",
  meter_type: "This field is required",
  amount: "This field is required",
  phone: "This field is required",
  email: "This field is required",
});

const purchase = ref({
  meter_no: "",
  provider: "",
  meter_type: "",
  amount: 0,
  phone: "",
});

const transactions = ref({
  meter_no: "",
  meter_name: "",
  provider: "",
  meter_type: "",
  amount: "",
  phone: "",
});

const getProvider = () => {
  store
    .dispatch("get", `/purchase/provider`)
    .then((resp) => {
      providers.value = resp.data.data;
    })
    .catch((err) => {
   
    });
};

const totalAmount = computed(() => {
  return Number(purchase.value.amount) + 100;
});

const saveBeneficiary = () => {
  saveAsBeneficiary.value;
};

const showSummary = () => {
  if (purchase.value.meter_no == "") {
    errors.value.meter_no = true;
    return;
  } else if (!purchase.value.meter_no.match(/^\d{11}$/)) {
    errors.value.meter_no = true;
    eMsg.value.meter_no = "Please Enter a valid Meter Number";
    return;
  } else {
    errors.value.meter_no = false;
  }

  if (purchase.value.provider == "") {
    errors.value.provider = true;
    return;
  } else {
    errors.value.provider = false;
  }

  if (purchase.value.meter_type == "") {
    errors.value.meter_type = true;
    return;
  } else {
    errors.value.meter_type = false;
  }

  if (purchase.value.amount == 0) {
    errors.value.amount = true;
    return;
  } else if (isNaN(purchase.value.amount) || purchase.value.amount <= 0) {
    errors.value.amount = true;
    eMsg.value.amount = "Please enter a valid amount";
    return;
  } else {
    errors.value.amount = false;
  }

  if (purchase.value.phone == "") {
    errors.value.phone = true;
    return;
  } else if (!phoneRegex.test(purchase.value.phone)) {
    errors.value.phone = true;
    eMsg.value.phone = "Please Enter a valid Phone Number";
    return;
  } else {
    errors.value.phone = false;
  }
  loading.value = true;

  store
    .dispatch("post", {
      endpoint: "/purchase/verify",
      details: {
        meter_no: purchase.value.meter_no,
        meter_type: purchase.value.meter_type,
        provider: purchase.value.provider,
      },
    })
    .then((resp) => {
      loading.value = false;
      apiResponse.Customer_Name = resp.data.data.Customer_Name;
      displayForm.value = false;
      summaryPage.value = true;
      useToast().success("Verified");
    })
    .catch((err) => {
      loading.value = false;
    });
};

const PurchaseSummary = () => {
  if (purchase.value.meter_no == "") {
    errors.value.meter_no = true;
    return;
  } else if (!purchase.value.meter_no.match(/^\d{11}$/)) {
    errors.value.meter_no = true;
    eMsg.value.meter_no = "Please Enter a valid Meter Number";
    return;
  } else {
    errors.value.meter_no = false;
  }

  if (purchase.value.provider == "") {
    errors.value.provider = true;
    return;
  } else {
    errors.value.provider = false;
  }

  if (purchase.value.meter_type == "") {
    errors.value.meter_type = true;
    return;
  } else {
    errors.value.meter_type = false;
  }

  if (purchase.value.amount == 0) {
    errors.value.amount = true;
    return;
  } else if (isNaN(purchase.value.amount) || purchase.value.amount <= 0) {
    errors.value.amount = true;
    eMsg.value.amount = "please enter a valid amount";
    return;
  } else {
    errors.value.amount = false;
  }
  if (purchase.value.phone == "") {
    errors.value.phone = true;
    return;
  } else if (!phoneRegex.test(purchase.value.phone)) {
    errors.value.phone = true;
    eMsg.value.phone = "Please Enter a valid Phone Number";
    return;
  } else {
    errors.value.phone = false;
  }
  loading.value = true;

  store
    .dispatch("post", {
      endpoint: "/purchase/verify",
      details: {
        meter_no: purchase.value.meter_no,
        meter_type: purchase.value.meter_type,
        provider: purchase.value.provider,
      },
    })
    .then((resp) => {
      loading.value = false;
      apiResponse.Customer_Name = resp.data.data.Customer_Name;
      displayForm.value = false;
      recentTransaction.value = false;
      purchaseSummary.value = true;
      useToast().success("Verified");
    })
    .catch((err) => {
      loading.value = false;
    });
};

const showPurchaseSummary = () => {
  recentTransaction.value = false;
  purchaseSummary.value = true;
  summaryPage.value = false;
  displayForm.value = false;
};

const closeModal = () => {
  const offcanvasElement = document.getElementById("offcanvasRight");
  const offcanvasBtn = document.getElementById("offcanvasBottom");

  const bsOffcanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
  const closer = bootstrap.Offcanvas.getInstance(offcanvasBtn);

  if (bsOffcanvas) {
    bsOffcanvas.hide();
  }

  if (closer) {
    closer.hide();
  }
};

const handleSelectBeneficiary = (beneficiary: any) => {
  purchase.value = beneficiary;
  displayForm.value = true;
  closeModal();
};
const Makepayment = () => {
  store
    .dispatch("post", {
      endpoint: "/purchase",
      details: {
        meter_no: purchase.value.meter_no,
        provider: purchase.value.provider,
        meter_type: purchase.value.meter_type,
        amount: purchase.value.amount,
        phone: purchase.value.phone,
        meter_name: apiResponse.Customer_Name,
        beneficiary: saveAsBeneficiary.value,
      },
    })
    .then((resp) => {
      useToast().success("Processing");
      window.location.href = resp.data.data;
    });
};

const getTransactions = () => {
  store.commit("setLoader", true);
  store
    .dispatch("get", `/purchase/recent`)
    .then((resp) => {
      store.commit("setLoader", false);
      transactions.value = resp.data.data;
      transaction.value = transactions.value;
      loadInfo.value = true;
    })
    .catch((err) => {
      store.commit("setLoader", false);
    });
};

const handleGuestPurchase = () => {
  store
    .dispatch("post", {
      endpoint: "/purchase/guest",
      details: {
        meter_no: purchase.value.meter_no,
        provider: purchase.value.provider,
        meter_type: purchase.value.meter_type,
        amount: purchase.value.amount,
        phone: purchase.value.phone,
        meter_name: apiResponse.Customer_Name,
        beneficiary: false,
      },
    })
    .then((resp) => {
      useToast().success("Processing");
      window.location.href = resp.data.data;
    })
    .catch((err) => {
    });
};

const showForm = () => {
  displayForm.value = true;
  summaryPage.value = false;
  recentTransaction.value = false;
};

const setTransaction = (item: any) => {
  transaction.value = item;
};



onMounted(() => {
  purchase.value.meter_no = localStorage.getItem("meter_no") || "";
  getProvider();
  if (store.state.user) {
    getTransactions();
    recentTransaction.value = true;
    displayForm.value = false;
  }
});

return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(store).state.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(History, { transaction: transaction.value }, null, 8, ["transaction"]),
          (recentTransaction.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (loadInfo.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      (transactions.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, [
                              _cache[26] || (_cache[26] = _createElementVNode("div", { class: "mb-3 border-bottom py-3" }, [
                                _createElementVNode("h5", null, "Most Recent Transaction")
                              ], -1)),
                              _createElementVNode("div", _hoisted_6, [
                                _createElementVNode("div", _hoisted_7, [
                                  _cache[17] || (_cache[17] = _createElementVNode("p", { class: "text-gray p-text" }, "Provider", -1)),
                                  _createElementVNode("div", _hoisted_8, _toDisplayString(transactions.value.provider), 1)
                                ]),
                                _createElementVNode("div", _hoisted_9, [
                                  _cache[18] || (_cache[18] = _createElementVNode("p", { class: "p-text text-gray" }, "Meter Type", -1)),
                                  _createElementVNode("p", _hoisted_10, _toDisplayString(transactions.value.meter_type), 1)
                                ]),
                                _createElementVNode("div", _hoisted_11, [
                                  _cache[19] || (_cache[19] = _createElementVNode("p", { class: "p-text text-gray" }, "Meter Number", -1)),
                                  _createElementVNode("p", _hoisted_12, _toDisplayString(transactions.value.meter_no), 1)
                                ]),
                                _createElementVNode("div", _hoisted_13, [
                                  _cache[20] || (_cache[20] = _createElementVNode("p", { class: "p-text text-gray" }, "Meter Name", -1)),
                                  _createElementVNode("p", _hoisted_14, _toDisplayString(transactions.value.meter_name), 1)
                                ]),
                                _createElementVNode("div", _hoisted_15, [
                                  _cache[21] || (_cache[21] = _createElementVNode("p", { class: "text-gray p-text" }, "Phone Number", -1)),
                                  _createElementVNode("p", _hoisted_16, _toDisplayString(transactions.value.phone), 1)
                                ]),
                                _createElementVNode("div", _hoisted_17, [
                                  _cache[22] || (_cache[22] = _createElementVNode("p", { class: "text-gray p-text" }, "Amount", -1)),
                                  _createElementVNode("p", _hoisted_18, "NGN " + _toDisplayString(transactions.value.amount), 1)
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_19, [
                                _createElementVNode("div", _hoisted_20, [
                                  _createElementVNode("div", _hoisted_21, [
                                    _createElementVNode("button", {
                                      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (showForm()), ["prevent"])),
                                      class: "btn-norm"
                                    }, _cache[23] || (_cache[23] = [
                                      _createElementVNode("span", null, " New Purchase ", -1)
                                    ]))
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_22, [
                                  _createElementVNode("div", _hoisted_23, [
                                    _createElementVNode("div", _hoisted_24, [
                                      _createElementVNode("button", {
                                        class: "btn-primary",
                                        "data-bs-toggle": "offcanvas",
                                        "data-bs-target": "#offcanvasRight2",
                                        "aria-controls": "offcanvasRight2",
                                        onClick: _cache[1] || (_cache[1] = ($event: any) => (setTransaction(transactions.value)))
                                      }, _cache[24] || (_cache[24] = [
                                        _createElementVNode("span", null, " Purchase Again ", -1)
                                      ]))
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_25, [
                                    _createElementVNode("div", _hoisted_26, [
                                      _createElementVNode("button", {
                                        class: "btn-primary",
                                        "data-bs-toggle": "offcanvas",
                                        "data-bs-target": "#offcanvasBottomB",
                                        "aria-controls": "offcanvasBottomB",
                                        onClick: _cache[2] || (_cache[2] = ($event: any) => (setTransaction(transactions.value)))
                                      }, _cache[25] || (_cache[25] = [
                                        _createElementVNode("span", null, " Purchase Again ", -1)
                                      ]))
                                    ])
                                  ])
                                ])
                              ])
                            ])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_27, [
                            _createVNode(Deskbeneficiary, { onSelectBeneficiary: handleSelectBeneficiary }),
                            _cache[38] || (_cache[38] = _createElementVNode("div", { class: "steps my-3" }, [
                              _createElementVNode("button", { class: "btn text-primary border-light rounded-pill" }, [
                                _createTextVNode(" Step "),
                                _createElementVNode("span", { class: "p" }, "1/2")
                              ])
                            ], -1)),
                            _createElementVNode("form", null, [
                              _createElementVNode("div", _hoisted_28, [
                                _createElementVNode("div", _hoisted_29, [
                                  _createElementVNode("div", _hoisted_30, [
                                    _cache[27] || (_cache[27] = _createElementVNode("label", {
                                      class: "mb-2",
                                      for: "meter"
                                    }, [
                                      _createTextVNode("Meter Number "),
                                      _createElementVNode("span", { class: "text-danger" }, "*")
                                    ], -1)),
                                    _createElementVNode("div", null, [
                                      _withDirectives(_createElementVNode("input", {
                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((purchase.value.meter_no) = $event)),
                                        type: "text",
                                        class: "form-control border-gray bg-gray",
                                        placeholder: "e.g 123456789"
                                      }, null, 512), [
                                        [_vModelText, purchase.value.meter_no]
                                      ])
                                    ]),
                                    (errors.value.meter_no)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_31, _toDisplayString(eMsg.value.meter_no), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  (_unref(store).state.user)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                        _createElementVNode("div", _hoisted_33, [
                                          _createElementVNode("div", _hoisted_34, [
                                            _createVNode(_component_Iconify, {
                                              icon: "mage:user",
                                              style: {"font-size":"20px","color":"#818181"}
                                            }),
                                            _cache[28] || (_cache[28] = _createElementVNode("p", { class: "p-text ms-1" }, "Select from Beneficiaries", -1))
                                          ])
                                        ]),
                                        _createElementVNode("div", _hoisted_35, [
                                          _createElementVNode("div", _hoisted_36, [
                                            _createVNode(_component_Iconify, {
                                              icon: "mage:user",
                                              style: {"font-size":"20px","color":"#818181"}
                                            }),
                                            _cache[29] || (_cache[29] = _createElementVNode("p", { class: "p-text ms-1" }, "Select from Beneficiaries", -1))
                                          ])
                                        ])
                                      ]))
                                    : _createCommentVNode("", true)
                                ]),
                                _createElementVNode("div", _hoisted_37, [
                                  _createElementVNode("div", _hoisted_38, [
                                    _cache[31] || (_cache[31] = _createElementVNode("label", {
                                      for: "meter",
                                      class: "mb-2"
                                    }, [
                                      _createTextVNode("Provider "),
                                      _createElementVNode("span", { class: "text-danger" }, "*")
                                    ], -1)),
                                    _createElementVNode("div", null, [
                                      _createElementVNode("div", _hoisted_39, [
                                        _createElementVNode("span", _hoisted_40, [
                                          _createVNode(_component_Iconify, {
                                            icon: "carbon:location",
                                            class: "pe-2",
                                            style: {"width":"30px","height":"30px","color":"#818181","border-right":"1px solid #c6c6c6"}
                                          })
                                        ]),
                                        _withDirectives(_createElementVNode("select", {
                                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((purchase.value.provider) = $event)),
                                          class: "form-select form-select-lg",
                                          "aria-label": "Large select example"
                                        }, [
                                          _cache[30] || (_cache[30] = _createElementVNode("option", {
                                            class: "px-2",
                                            disabled: "",
                                            value: ""
                                          }, " select option ", -1)),
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(providers.value, (provider, index) => {
                                            return (_openBlock(), _createElementBlock("option", {
                                              key: index,
                                              value: provider.serviceID
                                            }, _toDisplayString(provider.serviceID), 9, _hoisted_41))
                                          }), 128))
                                        ], 512), [
                                          [_vModelSelect, purchase.value.provider]
                                        ])
                                      ]),
                                      (errors.value.provider)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_42, _toDisplayString(eMsg.value.provider), 1))
                                        : _createCommentVNode("", true)
                                    ])
                                  ])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_43, [
                                _createElementVNode("div", _hoisted_44, [
                                  _createElementVNode("div", _hoisted_45, [
                                    _cache[33] || (_cache[33] = _createElementVNode("label", {
                                      class: "mb-2",
                                      for: "meter"
                                    }, [
                                      _createTextVNode("Meter Type "),
                                      _createElementVNode("span", { class: "text-danger" }, "*")
                                    ], -1)),
                                    _createElementVNode("div", null, [
                                      _withDirectives(_createElementVNode("select", {
                                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((purchase.value.meter_type) = $event)),
                                        class: "form-select form-select-lg",
                                        "aria-label": "Large select example"
                                      }, _cache[32] || (_cache[32] = [
                                        _createElementVNode("option", {
                                          class: "px-2",
                                          value: "",
                                          disabled: ""
                                        }, " select option ", -1),
                                        _createElementVNode("option", { value: "prepaid" }, "prepaid", -1),
                                        _createElementVNode("option", { value: "postpaid" }, "postpaid", -1)
                                      ]), 512), [
                                        [_vModelSelect, purchase.value.meter_type]
                                      ])
                                    ]),
                                    (errors.value.meter_type)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_46, _toDisplayString(eMsg.value.meter_type), 1))
                                      : _createCommentVNode("", true)
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_47, [
                                  _createElementVNode("div", _hoisted_48, [
                                    _cache[35] || (_cache[35] = _createElementVNode("label", {
                                      for: "meter",
                                      class: "mb-2"
                                    }, [
                                      _createTextVNode("Amount "),
                                      _createElementVNode("span", { class: "text-danger" }, "*")
                                    ], -1)),
                                    _createElementVNode("div", _hoisted_49, [
                                      _cache[34] || (_cache[34] = _createElementVNode("div", {
                                        class: "input-group-text p-2",
                                        id: "inputGroup-sizing-default"
                                      }, [
                                        _createElementVNode("span", { class: "bg-white te px-2 py-1" }, "NGN")
                                      ], -1)),
                                      _withDirectives(_createElementVNode("input", {
                                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((purchase.value.amount) = $event)),
                                        type: "number",
                                        class: "form-control border-gray bg-gray",
                                        placeholder: "0.00"
                                      }, null, 512), [
                                        [_vModelText, purchase.value.amount]
                                      ])
                                    ]),
                                    (errors.value.amount)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_50, _toDisplayString(eMsg.value.amount), 1))
                                      : _createCommentVNode("", true)
                                  ])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_51, [
                                _createElementVNode("div", _hoisted_52, [
                                  _createElementVNode("div", _hoisted_53, [
                                    _cache[37] || (_cache[37] = _createElementVNode("label", {
                                      for: "meter",
                                      class: "mb-2"
                                    }, [
                                      _createTextVNode("Phone Number "),
                                      _createElementVNode("span", { class: "text-danger" }, "*")
                                    ], -1)),
                                    _createElementVNode("div", _hoisted_54, [
                                      _createElementVNode("div", _hoisted_55, [
                                        _createVNode(_component_Iconify, {
                                          icon: "twemoji:flag-nigeria",
                                          style: {"width":"24px","height":"24x"}
                                        }),
                                        _cache[36] || (_cache[36] = _createElementVNode("span", {
                                          class: "pe-2",
                                          style: {"border-right":"1px solid gray"}
                                        }, " +234", -1))
                                      ]),
                                      _withDirectives(_createElementVNode("input", {
                                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((purchase.value.phone) = $event)),
                                        type: "tel",
                                        class: "form-control border-gray bg-gray",
                                        placeholder: "081*******",
                                        "aria-label": "Username",
                                        "aria-describedby": "basic-addon1"
                                      }, null, 512), [
                                        [_vModelText, purchase.value.phone]
                                      ])
                                    ]),
                                    (errors.value.phone)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_56, _toDisplayString(eMsg.value.phone), 1))
                                      : _createCommentVNode("", true)
                                  ])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_57, [
                                _createElementVNode("button", {
                                  onClick: _withModifiers(PurchaseSummary, ["prevent"]),
                                  class: "border-gray rounded text-white btn-primary px-4 py-2",
                                  type: "submit",
                                  disabled: loading.value
                                }, _toDisplayString(loading.value ? "Verifying Details..." : "Continue"), 9, _hoisted_58)
                              ])
                            ])
                          ]))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (displayForm.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
          _createVNode(Deskbeneficiary, { onSelectBeneficiary: handleSelectBeneficiary }),
          _cache[50] || (_cache[50] = _createElementVNode("div", { class: "steps my-3" }, [
            _createElementVNode("button", { class: "btn text-primary border-light rounded-pill" }, [
              _createTextVNode(" Step "),
              _createElementVNode("span", { class: "p" }, "1/2")
            ])
          ], -1)),
          _createElementVNode("form", null, [
            _createElementVNode("div", _hoisted_60, [
              _createElementVNode("div", _hoisted_61, [
                _createElementVNode("div", _hoisted_62, [
                  _cache[39] || (_cache[39] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Meter Number "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((purchase.value.meter_no) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray",
                      placeholder: "e.g 123456789"
                    }, null, 512), [
                      [_vModelText, purchase.value.meter_no]
                    ])
                  ]),
                  (errors.value.meter_no)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_63, _toDisplayString(eMsg.value.meter_no), 1))
                    : _createCommentVNode("", true)
                ]),
                (_unref(store).state.user)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
                      _createElementVNode("div", _hoisted_65, [
                        _createElementVNode("div", _hoisted_66, [
                          _createVNode(_component_Iconify, {
                            icon: "mage:user",
                            style: {"font-size":"20px","color":"#818181"}
                          }),
                          _cache[40] || (_cache[40] = _createElementVNode("p", { class: "p-text ms-1" }, "Select from Beneficiaries", -1))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_67, [
                        _createElementVNode("div", _hoisted_68, [
                          _createVNode(_component_Iconify, {
                            icon: "mage:user",
                            style: {"font-size":"20px","color":"#818181"}
                          }),
                          _cache[41] || (_cache[41] = _createElementVNode("p", { class: "p-text ms-1" }, "Select from Beneficiaries", -1))
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_69, [
                _createElementVNode("div", _hoisted_70, [
                  _cache[43] || (_cache[43] = _createElementVNode("label", {
                    for: "meter",
                    class: "mb-2"
                  }, [
                    _createTextVNode("Provider "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_71, [
                      _createElementVNode("span", _hoisted_72, [
                        _createVNode(_component_Iconify, {
                          icon: "carbon:location",
                          class: "pe-2",
                          style: {"width":"30px","height":"30px","color":"#818181","border-right":"1px solid #c6c6c6"}
                        })
                      ]),
                      _withDirectives(_createElementVNode("select", {
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((purchase.value.provider) = $event)),
                        class: "form-select form-select-lg",
                        "aria-label": "Large select example"
                      }, [
                        _cache[42] || (_cache[42] = _createElementVNode("option", {
                          class: "px-2",
                          disabled: "",
                          value: ""
                        }, "select option", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(providers.value, (provider, index) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: index,
                            value: provider.serviceID
                          }, _toDisplayString(provider.serviceID), 9, _hoisted_73))
                        }), 128))
                      ], 512), [
                        [_vModelSelect, purchase.value.provider]
                      ])
                    ]),
                    (errors.value.provider)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_74, _toDisplayString(eMsg.value.provider), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_75, [
              _createElementVNode("div", _hoisted_76, [
                _createElementVNode("div", _hoisted_77, [
                  _cache[45] || (_cache[45] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Meter Type "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("select", {
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((purchase.value.meter_type) = $event)),
                      class: "form-select form-select-lg",
                      "aria-label": "Large select example"
                    }, _cache[44] || (_cache[44] = [
                      _createElementVNode("option", {
                        class: "px-2",
                        value: "",
                        disabled: ""
                      }, "select option", -1),
                      _createElementVNode("option", { value: "prepaid" }, "prepaid", -1),
                      _createElementVNode("option", { value: "postpaid" }, "postpaid", -1)
                    ]), 512), [
                      [_vModelSelect, purchase.value.meter_type]
                    ])
                  ]),
                  (errors.value.meter_type)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_78, _toDisplayString(eMsg.value.meter_type), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_79, [
                _createElementVNode("div", _hoisted_80, [
                  _cache[47] || (_cache[47] = _createElementVNode("label", {
                    for: "meter",
                    class: "mb-2"
                  }, [
                    _createTextVNode("Amount "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", _hoisted_81, [
                    _cache[46] || (_cache[46] = _createElementVNode("div", {
                      class: "input-group-text p-2",
                      id: "inputGroup-sizing-default"
                    }, [
                      _createElementVNode("span", { class: "bg-white te px-2 py-1" }, "NGN")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((purchase.value.amount) = $event)),
                      type: "number",
                      class: "form-control border-gray bg-gray",
                      placeholder: "0.00"
                    }, null, 512), [
                      [_vModelText, purchase.value.amount]
                    ])
                  ]),
                  (errors.value.amount)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_82, _toDisplayString(eMsg.value.amount), 1))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_83, [
              _createElementVNode("div", _hoisted_84, [
                _createElementVNode("div", _hoisted_85, [
                  _cache[49] || (_cache[49] = _createElementVNode("label", {
                    for: "meter",
                    class: "mb-2"
                  }, [
                    _createTextVNode("Phone Number "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", _hoisted_86, [
                    _createElementVNode("div", _hoisted_87, [
                      _createVNode(_component_Iconify, {
                        icon: "twemoji:flag-nigeria",
                        style: {"width":"24px","height":"24x"}
                      }),
                      _cache[48] || (_cache[48] = _createElementVNode("span", {
                        class: "pe-2",
                        style: {"border-right":"1px solid gray"}
                      }, " +234", -1))
                    ]),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((purchase.value.phone) = $event)),
                      type: "tel",
                      class: "form-control border-gray bg-gray",
                      placeholder: "081*******",
                      "aria-label": "Username",
                      "aria-describedby": "basic-addon1"
                    }, null, 512), [
                      [_vModelText, purchase.value.phone]
                    ])
                  ]),
                  (errors.value.phone)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_88, _toDisplayString(eMsg.value.phone), 1))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            (_unref(store).state.user)
              ? (_openBlock(), _createElementBlock("div", _hoisted_89, [
                  _createElementVNode("button", {
                    onClick: _withModifiers(PurchaseSummary, ["prevent"]),
                    class: "border-gray rounded text-white btn-primary px-4 py-2",
                    type: "submit",
                    disabled: loading.value
                  }, _toDisplayString(loading.value ? "Verifying Details..." : "Continue"), 9, _hoisted_90)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_91, [
                  _createElementVNode("button", {
                    onClick: _withModifiers(showSummary, ["prevent"]),
                    class: "border-gray rounded text-white btn-primary px-4 py-2",
                    type: "submit",
                    disabled: loading.value
                  }, _toDisplayString(loading.value ? "Verifying Details..." : "Continue"), 9, _hoisted_92)
                ]))
          ])
        ]))
      : _createCommentVNode("", true),
    (summaryPage.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_93, [
          _createElementVNode("div", _hoisted_94, [
            _createElementVNode("div", _hoisted_95, [
              _createElementVNode("div", _hoisted_96, [
                _createElementVNode("div", {
                  onClick: _cache[13] || (_cache[13] = _withModifiers(($event: any) => (showForm()), ["prevent"])),
                  class: "back-icon mr-2"
                }, [
                  _createVNode(_component_Iconify, { icon: "ep:arrow-left" })
                ]),
                _cache[51] || (_cache[51] = _createElementVNode("p", { class: "p-text" }, "Summary", -1))
              ]),
              _cache[52] || (_cache[52] = _createElementVNode("div", null, [
                _createElementVNode("button", { class: "btn text-primary border-light rounded-pill" }, [
                  _createTextVNode(" Step"),
                  _createElementVNode("span", { class: "p" }, "2/2")
                ])
              ], -1))
            ]),
            _createElementVNode("div", _hoisted_97, [
              _createElementVNode("div", _hoisted_98, [
                _cache[53] || (_cache[53] = _createElementVNode("p", { class: "text-gray p-text" }, "Provider", -1)),
                _createElementVNode("div", _hoisted_99, _toDisplayString(purchase.value.provider), 1)
              ]),
              _createElementVNode("div", _hoisted_100, [
                _cache[54] || (_cache[54] = _createElementVNode("p", { class: "text-gray p-text" }, "Meter Type", -1)),
                _createElementVNode("p", _hoisted_101, _toDisplayString(purchase.value.meter_type), 1)
              ]),
              _createElementVNode("div", _hoisted_102, [
                _cache[55] || (_cache[55] = _createElementVNode("p", { class: "text-gray p-text" }, "Meter Number", -1)),
                _createElementVNode("p", _hoisted_103, _toDisplayString(purchase.value.meter_no), 1)
              ]),
              _createElementVNode("div", _hoisted_104, [
                _cache[56] || (_cache[56] = _createElementVNode("p", { class: "text-gray p-text" }, "Meter Name", -1)),
                _createElementVNode("p", _hoisted_105, _toDisplayString(apiResponse.Customer_Name), 1)
              ]),
              _createElementVNode("div", _hoisted_106, [
                _cache[57] || (_cache[57] = _createElementVNode("p", { class: "text-gray p-text" }, "Phone Number", -1)),
                _createElementVNode("p", _hoisted_107, _toDisplayString(purchase.value.phone), 1)
              ]),
              _createElementVNode("div", _hoisted_108, [
                _cache[58] || (_cache[58] = _createElementVNode("p", { class: "text-gray p-text" }, "Amount", -1)),
                _createElementVNode("p", _hoisted_109, "NGN " + _toDisplayString(purchase.value.amount), 1)
              ]),
              _cache[59] || (_cache[59] = _createElementVNode("div", { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }, [
                _createElementVNode("p", { class: "text-gray p-text" }, "Fee"),
                _createElementVNode("p", { class: "p-text" }, "NGN 100.00")
              ], -1))
            ]),
            _createElementVNode("div", _hoisted_110, [
              _cache[60] || (_cache[60] = _createElementVNode("p", { class: "text-gray p-text" }, "Total Amount", -1)),
              _createElementVNode("p", _hoisted_111, "NGN " + _toDisplayString(_unref(formatAmount)(totalAmount.value)), 1)
            ]),
            _createElementVNode("div", _hoisted_112, [
              _createElementVNode("div", _hoisted_113, [
                _cache[61] || (_cache[61] = _createElementVNode("button", {
                  class: "btn-primary border-gray",
                  "data-bs-target": "#exampleModalToggle",
                  "data-bs-toggle": "modal"
                }, [
                  _createElementVNode("span", { class: "fw-bold" }, "Proceed to Pay")
                ], -1)),
                _createVNode(CreateAccount, {
                  purchase: purchase.value,
                  onGuestPurchase: handleGuestPurchase,
                  onContinue: showPurchaseSummary
                }, null, 8, ["purchase"])
              ])
            ]),
            _createElementVNode("div", _hoisted_114, [
              _createElementVNode("div", _hoisted_115, [
                _cache[62] || (_cache[62] = _createElementVNode("button", {
                  class: "btn btn-primary border-gray",
                  type: "button",
                  "data-bs-toggle": "offcanvas",
                  "data-bs-target": "#offcanvasBottomA",
                  "aria-controls": "offcanvasBottomA"
                }, [
                  _createElementVNode("span", { class: "fw-bold" }, "Proceed to Pay")
                ], -1)),
                _createVNode(CreateAccountMOB, {
                  purchase: purchase.value,
                  onGuestPurchase: handleGuestPurchase,
                  onContinue: showPurchaseSummary
                }, null, 8, ["purchase"])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (purchaseSummary.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_116, [
          _createElementVNode("div", _hoisted_117, [
            _createElementVNode("div", _hoisted_118, [
              _createElementVNode("div", _hoisted_119, [
                _createElementVNode("div", _hoisted_120, [
                  _createVNode(_component_Iconify, { icon: "ep:arrow-left" }),
                  _createVNode(Return, { onPurchaseHistory: showPurchaseSummary })
                ]),
                _cache[63] || (_cache[63] = _createElementVNode("p", { class: "p-text" }, "Purchase Summary", -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_121, [
              _createElementVNode("div", _hoisted_122, [
                _cache[64] || (_cache[64] = _createElementVNode("p", { class: "text-gray p-text" }, "Provider", -1)),
                _createElementVNode("div", _hoisted_123, _toDisplayString(purchase.value.provider), 1)
              ]),
              _createElementVNode("div", _hoisted_124, [
                _cache[65] || (_cache[65] = _createElementVNode("p", { class: "p-text text-gray" }, "Meter Type", -1)),
                _createElementVNode("p", _hoisted_125, _toDisplayString(purchase.value.meter_type), 1)
              ]),
              _createElementVNode("div", _hoisted_126, [
                _cache[66] || (_cache[66] = _createElementVNode("p", { class: "p-text text-gray" }, "Meter Number", -1)),
                _createElementVNode("p", _hoisted_127, _toDisplayString(purchase.value.meter_no), 1)
              ]),
              _createElementVNode("div", _hoisted_128, [
                _cache[67] || (_cache[67] = _createElementVNode("p", { class: "p-text text-gray" }, "Meter Name", -1)),
                _createElementVNode("p", _hoisted_129, _toDisplayString(apiResponse.Customer_Name), 1)
              ]),
              _createElementVNode("div", _hoisted_130, [
                _cache[68] || (_cache[68] = _createElementVNode("p", { class: "text-gray p-text" }, "Phone Number", -1)),
                _createElementVNode("p", _hoisted_131, _toDisplayString(purchase.value.phone), 1)
              ]),
              _createElementVNode("div", _hoisted_132, [
                _cache[69] || (_cache[69] = _createElementVNode("p", { class: "text-gray p-text" }, "Amount", -1)),
                _createElementVNode("p", _hoisted_133, "NGN " + _toDisplayString(purchase.value.amount), 1)
              ]),
              _cache[70] || (_cache[70] = _createElementVNode("div", { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }, [
                _createElementVNode("p", { class: "text-gray p-text" }, "Fee"),
                _createElementVNode("p", { class: "p-text" }, "NGN 100.00")
              ], -1))
            ]),
            _createElementVNode("div", _hoisted_134, [
              _cache[71] || (_cache[71] = _createElementVNode("p", { class: "text-gray p-text" }, "Total Amount", -1)),
              _createElementVNode("p", _hoisted_135, "NGN " + _toDisplayString(_unref(formatAmount)(totalAmount.value)), 1)
            ]),
            _createElementVNode("div", _hoisted_136, [
              _createElementVNode("div", _hoisted_137, [
                _cache[72] || (_cache[72] = _createElementVNode("label", {
                  class: "form-check-label text-gray",
                  for: "flexSwitchCheckChecked"
                }, "Save meter details as a beneficiary", -1)),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((saveAsBeneficiary).value = $event)),
                  class: "form-check-input",
                  style: {"height":"20px"},
                  type: "checkbox",
                  role: "switch",
                  id: "flexSwitchCheckChecked",
                  value: "false",
                  onChange: _cache[15] || (_cache[15] = ($event: any) => (saveBeneficiary()))
                }, null, 544), [
                  [_vModelCheckbox, saveAsBeneficiary.value]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_138, [
              _createElementVNode("button", {
                onClick: _cache[16] || (_cache[16] = _withModifiers(($event: any) => (Makepayment()), ["prevent"])),
                class: "btn-primary border-gray",
                disabled: loading.value
              }, [
                _createElementVNode("span", _hoisted_140, _toDisplayString(loading.value ? "Loading..." : "Proceed to Pay"), 1)
              ], 8, _hoisted_139)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})