<template>
  <div class="navbar-wrapper">
    <div class="navbar-items">
        <div class="d-flex align-items-center">
          <Iconify
            @click="toggleSidebar()"
            class="me-3 toggler"
            style="font-size: 24px"
            icon="solar:hamburger-menu-outline"
          />

          <div>
            <p class="fs-5">{{ route.name }}</p>
          </div>
        </div>


      <div class="desktop-screen">
        <div class="d-flex align-items-center">
          <div
            v-if="store.state.user"
            class="d-flex align-items-center me-3 px-4 border-end"
          >
            <span>
              <Iconify icon="bi:phone" stye="font-size: 24px;" />
              {{ store.state.user ? store.state.user.phone : "" }}</span
            >
          </div>
          <div class="navbar-profile d-flex align-items-center">
            <div
              class="user-icon border d-flex justify-content-center align-items-center"
            >
              <Iconify icon="solar:user-outline" />
            </div>
            <div>
              <span
                class="dropdown-toggle ms-2"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              ></span>
              <div
                @click.prevent="logout()"
                class="mt-5 border-primary text-center dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <Iconify
                  icon="solar:user-linear"
                  style="color: #ff6600; font-size: 22px"
                />
                <span class="menu-text ms-2">Log Out</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const route = useRoute();
const store = useStore();

const logout = () => {
  store.commit("logout");
};

const showsidebar = computed(() => {
  return store.state.showsidebar;
});

const toggleSidebar = () => {
  store.commit("toggleSidebar", !showsidebar.value);
};
</script>

<style lang="scss" scoped>

</style>
