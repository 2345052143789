<script lang="ts" setup>
import { ref, reactive, onMounted, computed } from "vue";
import CreateAccount from "@/components/Modal/CreateAccount.vue";
import Return from "@/components/Modal/Return.vue";
import CreateAccountMOB from "@/components/BottomSheet/CreateAccount/CreateAccountMOB.vue";
import Deskbeneficiary from "@/components/BottomSheet/Beneficiary/SaveAsBeneficiary.vue";

import History from "@/components/BottomSheet/RecentTransaction.vue";

import { useRouter } from "vue-router";
import { formatAmount } from "@/core/utils/helpers";
import { useStore } from "vuex";
import { useToast } from "vue-toast-notification";

const store = useStore();
const summaryPage = ref(false);
const purchaseSummary = ref(false);
const router = useRouter();
const loading = ref(false);
const displayForm = ref(true);
const recentTransaction = ref(false);
const loadInfo = ref(false);
const providers: any = ref([]);

const saveAsBeneficiary = ref(false);
const transaction: any = ref({});
const phoneRegex = /^0(7[0]|8[0-1]|9[0-1])[0-9]{8}$/;

const apiResponse = reactive({
  Customer_Name: "",
});

const errors = ref({
  meter_no: false,
  provider: false,
  meter_type: false,
  amount: false,
  phone: false,
  email: false,
});

const eMsg = ref({
  meter_no: " This field is required",
  provider: "This field is required",
  meter_type: "This field is required",
  amount: "This field is required",
  phone: "This field is required",
  email: "This field is required",
});

const purchase = ref({
  meter_no: "",
  provider: "",
  meter_type: "",
  amount: 0,
  phone: "",
});

const transactions = ref({
  meter_no: "",
  meter_name: "",
  provider: "",
  meter_type: "",
  amount: "",
  phone: "",
});

const getProvider = () => {
  store
    .dispatch("get", `/purchase/provider`)
    .then((resp) => {
      providers.value = resp.data.data;
    })
    .catch((err) => {
   
    });
};

const totalAmount = computed(() => {
  return Number(purchase.value.amount) + 100;
});

const saveBeneficiary = () => {
  saveAsBeneficiary.value;
};

const showSummary = () => {
  if (purchase.value.meter_no == "") {
    errors.value.meter_no = true;
    return;
  } else if (!purchase.value.meter_no.match(/^\d{11}$/)) {
    errors.value.meter_no = true;
    eMsg.value.meter_no = "Please Enter a valid Meter Number";
    return;
  } else {
    errors.value.meter_no = false;
  }

  if (purchase.value.provider == "") {
    errors.value.provider = true;
    return;
  } else {
    errors.value.provider = false;
  }

  if (purchase.value.meter_type == "") {
    errors.value.meter_type = true;
    return;
  } else {
    errors.value.meter_type = false;
  }

  if (purchase.value.amount == 0) {
    errors.value.amount = true;
    return;
  } else if (isNaN(purchase.value.amount) || purchase.value.amount <= 0) {
    errors.value.amount = true;
    eMsg.value.amount = "Please enter a valid amount";
    return;
  } else {
    errors.value.amount = false;
  }

  if (purchase.value.phone == "") {
    errors.value.phone = true;
    return;
  } else if (!phoneRegex.test(purchase.value.phone)) {
    errors.value.phone = true;
    eMsg.value.phone = "Please Enter a valid Phone Number";
    return;
  } else {
    errors.value.phone = false;
  }
  loading.value = true;

  store
    .dispatch("post", {
      endpoint: "/purchase/verify",
      details: {
        meter_no: purchase.value.meter_no,
        meter_type: purchase.value.meter_type,
        provider: purchase.value.provider,
      },
    })
    .then((resp) => {
      loading.value = false;
      apiResponse.Customer_Name = resp.data.data.Customer_Name;
      displayForm.value = false;
      summaryPage.value = true;
      useToast().success("Verified");
    })
    .catch((err) => {
      loading.value = false;
    });
};

const PurchaseSummary = () => {
  if (purchase.value.meter_no == "") {
    errors.value.meter_no = true;
    return;
  } else if (!purchase.value.meter_no.match(/^\d{11}$/)) {
    errors.value.meter_no = true;
    eMsg.value.meter_no = "Please Enter a valid Meter Number";
    return;
  } else {
    errors.value.meter_no = false;
  }

  if (purchase.value.provider == "") {
    errors.value.provider = true;
    return;
  } else {
    errors.value.provider = false;
  }

  if (purchase.value.meter_type == "") {
    errors.value.meter_type = true;
    return;
  } else {
    errors.value.meter_type = false;
  }

  if (purchase.value.amount == 0) {
    errors.value.amount = true;
    return;
  } else if (isNaN(purchase.value.amount) || purchase.value.amount <= 0) {
    errors.value.amount = true;
    eMsg.value.amount = "please enter a valid amount";
    return;
  } else {
    errors.value.amount = false;
  }
  if (purchase.value.phone == "") {
    errors.value.phone = true;
    return;
  } else if (!phoneRegex.test(purchase.value.phone)) {
    errors.value.phone = true;
    eMsg.value.phone = "Please Enter a valid Phone Number";
    return;
  } else {
    errors.value.phone = false;
  }
  loading.value = true;

  store
    .dispatch("post", {
      endpoint: "/purchase/verify",
      details: {
        meter_no: purchase.value.meter_no,
        meter_type: purchase.value.meter_type,
        provider: purchase.value.provider,
      },
    })
    .then((resp) => {
      loading.value = false;
      apiResponse.Customer_Name = resp.data.data.Customer_Name;
      displayForm.value = false;
      recentTransaction.value = false;
      purchaseSummary.value = true;
      useToast().success("Verified");
    })
    .catch((err) => {
      loading.value = false;
    });
};

const showPurchaseSummary = () => {
  recentTransaction.value = false;
  purchaseSummary.value = true;
  summaryPage.value = false;
  displayForm.value = false;
};

const closeModal = () => {
  const offcanvasElement = document.getElementById("offcanvasRight");
  const offcanvasBtn = document.getElementById("offcanvasBottom");

  const bsOffcanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
  const closer = bootstrap.Offcanvas.getInstance(offcanvasBtn);

  if (bsOffcanvas) {
    bsOffcanvas.hide();
  }

  if (closer) {
    closer.hide();
  }
};

const handleSelectBeneficiary = (beneficiary: any) => {
  purchase.value = beneficiary;
  displayForm.value = true;
  closeModal();
};
const Makepayment = () => {
  store
    .dispatch("post", {
      endpoint: "/purchase",
      details: {
        meter_no: purchase.value.meter_no,
        provider: purchase.value.provider,
        meter_type: purchase.value.meter_type,
        amount: purchase.value.amount,
        phone: purchase.value.phone,
        meter_name: apiResponse.Customer_Name,
        beneficiary: saveAsBeneficiary.value,
      },
    })
    .then((resp) => {
      useToast().success("Processing");
      window.location.href = resp.data.data;
    });
};

const getTransactions = () => {
  store.commit("setLoader", true);
  store
    .dispatch("get", `/purchase/recent`)
    .then((resp) => {
      store.commit("setLoader", false);
      transactions.value = resp.data.data;
      transaction.value = transactions.value;
      loadInfo.value = true;
    })
    .catch((err) => {
      store.commit("setLoader", false);
    });
};

const handleGuestPurchase = () => {
  store
    .dispatch("post", {
      endpoint: "/purchase/guest",
      details: {
        meter_no: purchase.value.meter_no,
        provider: purchase.value.provider,
        meter_type: purchase.value.meter_type,
        amount: purchase.value.amount,
        phone: purchase.value.phone,
        meter_name: apiResponse.Customer_Name,
        beneficiary: false,
      },
    })
    .then((resp) => {
      useToast().success("Processing");
      window.location.href = resp.data.data;
    })
    .catch((err) => {
    });
};

const showForm = () => {
  displayForm.value = true;
  summaryPage.value = false;
  recentTransaction.value = false;
};

const setTransaction = (item: any) => {
  transaction.value = item;
};



onMounted(() => {
  purchase.value.meter_no = localStorage.getItem("meter_no") || "";
  getProvider();
  if (store.state.user) {
    getTransactions();
    recentTransaction.value = true;
    displayForm.value = false;
  }
});
</script>

<template>
  <div v-if="store.state.user">
    <History :transaction="transaction"></History>
    <div v-if="recentTransaction">
      <div v-if="loadInfo">
        <div
          class="step2-wrapper mt-4 d-flex justify-content-center"
          v-if="transactions"
        >
          <div class="summary-page">
            <div class="mb-3 border-bottom py-3">
              <h5>Most Recent Transaction</h5>
            </div>

            <div class="card-wrapper border rounded">
              <div
                class="provider px-3 py-1 border-bottom d-flex justify-content-between align-items-center"
              >
                <p class="text-gray p-text">Provider</p>
                <div class="provider-logo rounded p-2">
                  {{ transactions.provider }}
                </div>
              </div>
              <div
                class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
              >
                <p class="p-text text-gray">Meter Type</p>
                <p class="p-text">{{ transactions.meter_type }}</p>
              </div>
              <div
                class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
              >
                <p class="p-text text-gray">Meter Number</p>
                <p class="p-text">{{ transactions.meter_no }}</p>
              </div>
              <div
                class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
              >
                <p class="p-text text-gray">Meter Name</p>
                <p class="p-text">{{ transactions.meter_name }}</p>
              </div>
              <div
                class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
              >
                <p class="text-gray p-text">Phone Number</p>
                <p class="p-text">{{ transactions.phone }}</p>
              </div>
              <div
                class="provider p-3 d-flex justify-content-between align-items-center"
              >
                <p class="text-gray p-text">Amount</p>
                <p class="p-text">NGN {{ transactions.amount }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="d-grid mt-4">
                  <button @click.prevent="showForm()" class="btn-norm">
                    <span> New Purchase </span>
                  </button>
                </div>
              </div>
              <div class="col-6">
                <div class="desktop-screen">
                  <div class="d-grid mt-4">
                    <button
                      class="btn-primary"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight2"
                      aria-controls="offcanvasRight2"
                      @click="setTransaction(transactions)"
                    >
                      <span> Purchase Again </span>
                    </button>
                  </div>
                </div>
                <div class="mobile-screen">
                  <div class="d-grid mt-4">
                    <button
                      class="btn-primary"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasBottomB"
                      aria-controls="offcanvasBottomB"
                      @click="setTransaction(transactions)"
                    >
                      <span> Purchase Again </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <Deskbeneficiary
                    @selectBeneficiary="handleSelectBeneficiary"
                  />
          <div class="steps my-3">
            <button class="btn text-primary border-light rounded-pill">
              Step <span class="p">1/2</span>
            </button>
          </div>

          <form>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="each-field mb-3">
                  <label class="mb-2" for="meter"
                    >Meter Number <span class="text-danger">*</span></label
                  >
                  <div>
                    <input
                      v-model="purchase.meter_no"
                      type="text"
                      class="form-control border-gray bg-gray"
                      placeholder="e.g 123456789"
                    />
                  </div>
                  <div v-if="errors.meter_no" class="error-msg">
                    {{ eMsg.meter_no }}
                  </div>
                </div>
                <div v-if="store.state.user" class="my-4">
                  <div class="mobile-screen">
                    <div
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasBottom"
                      aria-controls="offcanvasBottom"
                      class="d-flex align-items-center text-gray"
                    >
                      <Iconify
                        icon="mage:user"
                        style="font-size: 20px; color: #818181"
                      />
                      <p class="p-text ms-1">Select from Beneficiaries</p>
                    </div>
                  </div>
               
                  <div class="desktop-screen">
                    <div
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                      class="d-flex align-items-center text-gray"
                    >
                      <Iconify
                        icon="mage:user"
                        style="font-size: 20px; color: #818181"
                      />
                      <p class="p-text ms-1">Select from Beneficiaries</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="each-field mb-3">
                  <label for="meter" class="mb-2"
                    >Provider <span class="text-danger">*</span></label
                  >
                  <div>
                    <div class="input-group mb-3">
                      <span
                        class="input-group-text"
                        id="inputGroup-sizing-default"
                        ><Iconify
                          icon="carbon:location"
                          class="pe-2"
                          style="
                            width: 30px;
                            height: 30px;
                            color: #818181;
                            border-right: 1px solid #c6c6c6;
                          "
                        />
                      </span>

                      <select
                        v-model="purchase.provider"
                        class="form-select form-select-lg"
                        aria-label="Large select example"
                      >
                        <option class="px-2" disabled value="">
                          select option
                        </option>
                        <option
                          v-for="(provider, index) in providers"
                          :key="index"
                          :value="provider.serviceID"
                        >
                          {{ provider.serviceID }}
                        </option>
                      </select>
                    </div>
                    <div v-if="errors.provider" class="error-msg">
                      {{ eMsg.provider }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="each-field mb-3">
                  <label class="mb-2" for="meter"
                    >Meter Type <span class="text-danger">*</span></label
                  >
                  <div>
                    <select
                      v-model="purchase.meter_type"
                      class="form-select form-select-lg"
                      aria-label="Large select example"
                    >
                      <option class="px-2" value="" disabled>
                        select option
                      </option>
                      <option value="prepaid">prepaid</option>
                      <option value="postpaid">postpaid</option>
                    </select>
                  </div>
                  <div v-if="errors.meter_type" class="error-msg">
                    {{ eMsg.meter_type }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="each-field mb-3">
                  <label for="meter" class="mb-2"
                    >Amount <span class="text-danger">*</span></label
                  >
                  <div class="input-group">
                    <div
                      class="input-group-text p-2"
                      id="inputGroup-sizing-default"
                    >
                      <span class="bg-white te px-2 py-1">NGN</span>
                    </div>

                    <input
                      v-model="purchase.amount"
                      type="number"
                      class="form-control border-gray bg-gray"
                      placeholder="0.00"
                    />
                  </div>
                  <div v-if="errors.amount" class="error-msg">
                    {{ eMsg.amount }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="each-field mb-3">
                  <label for="meter" class="mb-2"
                    >Phone Number <span class="text-danger">*</span></label
                  >
                  <div class="input-group">
                    <div class="input-group-text" id="basic-addon1">
                      <Iconify
                        icon="twemoji:flag-nigeria"
                        style="width: 24px; height: 24x"
                      /><span class="pe-2" style="border-right: 1px solid gray">
                        +234</span
                      >
                    </div>

                    <input
                      v-model="purchase.phone"
                      type="tel"
                      class="form-control border-gray bg-gray"
                      placeholder="081*******"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <div v-if="errors.phone" class="error-msg">
                    {{ eMsg.phone }}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-btn mt-3">
              <button
                @click.prevent="PurchaseSummary"
                class="border-gray rounded text-white btn-primary px-4 py-2"
                type="submit"
                :disabled="loading"
              >
                {{ loading ? "Verifying Details..." : "Continue" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div v-if="displayForm" class="form">
    <Deskbeneficiary @selectBeneficiary="handleSelectBeneficiary" />
    <div class="steps my-3">
      <button class="btn text-primary border-light rounded-pill">
        Step <span class="p">1/2</span>
      </button>
    </div>

    <form>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="each-field mb-3">
            <label class="mb-2" for="meter"
              >Meter Number <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="purchase.meter_no"
                type="text"
                class="form-control border-gray bg-gray"
                placeholder="e.g 123456789"
              />
            </div>
            <div v-if="errors.meter_no" class="error-msg">
              {{ eMsg.meter_no }}
            </div>
          </div>
          <div v-if="store.state.user" class="my-4">
            <div class="mobile-screen">
              <div
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottom"
                aria-controls="offcanvasBottom"
                class="d-flex align-items-center text-gray"
              >
                <Iconify
                  icon="mage:user"
                  style="font-size: 20px; color: #818181"
                />
                <p class="p-text ms-1">Select from Beneficiaries</p>
              </div>
            </div>

            <div class="desktop-screen">
              <div
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
                class="d-flex align-items-center text-gray"
              >
                <Iconify
                  icon="mage:user"
                  style="font-size: 20px; color: #818181"
                />
                <p class="p-text ms-1">Select from Beneficiaries</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="each-field mb-3">
            <label for="meter" class="mb-2"
              >Provider <span class="text-danger">*</span></label
            >
            <div>
              <div class="input-group mb-3">
                <span class="input-group-text" id="inputGroup-sizing-default"
                  ><Iconify
                    icon="carbon:location"
                    class="pe-2"
                    style="
                      width: 30px;
                      height: 30px;
                      color: #818181;
                      border-right: 1px solid #c6c6c6;
                    "
                  />
                </span>

                <select
                  v-model="purchase.provider"
                  class="form-select form-select-lg"
                  aria-label="Large select example"
                >
                  <option class="px-2" disabled value="">select option</option>
                  <option
                    v-for="(provider, index) in providers"
                    :key="index"
                    :value="provider.serviceID"
                  >
                    {{ provider.serviceID }}
                  </option>
                </select>
              </div>
              <div v-if="errors.provider" class="error-msg">
                {{ eMsg.provider }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="each-field mb-3">
            <label class="mb-2" for="meter"
              >Meter Type <span class="text-danger">*</span></label
            >
            <div>
              <select
                v-model="purchase.meter_type"
                class="form-select form-select-lg"
                aria-label="Large select example"
              >
                <option class="px-2" value="" disabled>select option</option>
                <option value="prepaid">prepaid</option>
                <option value="postpaid">postpaid</option>
              </select>
            </div>
            <div v-if="errors.meter_type" class="error-msg">
              {{ eMsg.meter_type }}
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="each-field mb-3">
            <label for="meter" class="mb-2"
              >Amount <span class="text-danger">*</span></label
            >
            <div class="input-group">
              <div class="input-group-text p-2" id="inputGroup-sizing-default">
                <span class="bg-white te px-2 py-1">NGN</span>
              </div>

              <input
                v-model="purchase.amount"
                type="number"
                class="form-control border-gray bg-gray"
                placeholder="0.00"
              />
            </div>
            <div v-if="errors.amount" class="error-msg">
              {{ eMsg.amount }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="each-field mb-3">
            <label for="meter" class="mb-2"
              >Phone Number <span class="text-danger">*</span></label
            >
            <div class="input-group">
              <div class="input-group-text" id="basic-addon1">
                <Iconify
                  icon="twemoji:flag-nigeria"
                  style="width: 24px; height: 24x"
                /><span class="pe-2" style="border-right: 1px solid gray">
                  +234</span
                >
              </div>

              <input
                v-model="purchase.phone"
                type="tel"
                class="form-control border-gray bg-gray"
                placeholder="081*******"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <div v-if="errors.phone" class="error-msg">{{ eMsg.phone }}</div>
          </div>
        </div>
      </div>
      <div v-if="store.state.user" class="form-btn mt-4">
        <button
          @click.prevent="PurchaseSummary"
          class="border-gray rounded text-white btn-primary px-4 py-2"
          type="submit"
          :disabled="loading"
        >
          {{ loading ? "Verifying Details..." : "Continue" }}
        </button>
      </div>

      <div v-else class="form-btn mt-3">
        <button
          @click.prevent="showSummary"
          class="border-gray rounded text-white btn-primary px-4 py-2"
          type="submit"
          :disabled="loading"
        >
          {{ loading ? "Verifying Details..." : "Continue" }}
        </button>
      </div>
    </form>
  </div>

  <!-- step 2-->

  <div
    v-if="summaryPage"
    class="step2-wrapper mt-3 d-flex justify-content-center"
  >
    <div class="summary-page">
      <div
        class="d-flex justify-content-between align-items-center mb-3 border-bottom py-3"
      >
        <div class="d-flex align-items-center">
          <div @click.prevent="showForm()" class="back-icon mr-2">
            <Iconify icon="ep:arrow-left" />
          </div>
          <p class="p-text">Summary</p>
        </div>

        <div>
          <button class="btn text-primary border-light rounded-pill">
            Step<span class="p">2/2</span>
          </button>
        </div>
      </div>

      <div class="card-wrapper border rounded">
        <div
          class="provider px-3 py-1 border-bottom d-flex justify-content-between align-items-center"
        >
          <p class="text-gray p-text">Provider</p>
          <div class="provider-logo p-2 rounded">
            {{ purchase.provider }}
          </div>
        </div>
        <div
          class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
        >
          <p class="text-gray p-text">Meter Type</p>
          <p class="p-text">{{ purchase.meter_type }}</p>
        </div>
        <div
          class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
        >
          <p class="text-gray p-text">Meter Number</p>
          <p class="p-text">{{ purchase.meter_no }}</p>
        </div>
        <div
          class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
        >
          <p class="text-gray p-text">Meter Name</p>
          <p class="p-text">{{ apiResponse.Customer_Name }}</p>
        </div>
        <div
          class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
        >
          <p class="text-gray p-text">Phone Number</p>
          <p class="p-text">{{ purchase.phone }}</p>
        </div>
        <div
          class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
        >
          <p class="text-gray p-text">Amount</p>
          <p class="p-text">NGN {{ purchase.amount }}</p>
        </div>
        <div
          class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
        >
          <p class="text-gray p-text">Fee</p>
          <p class="p-text">NGN 100.00</p>
        </div>
      </div>
      <div
        class="d-flex justify-content-between p-3 align-items-center mt-3 border-primary-light rounded"
      >
        <p class="text-gray p-text">Total Amount</p>
        <p class="p-text">NGN {{ formatAmount(totalAmount) }}</p>
      </div>

      <div class="desktop-screen">
        <div class="d-grid mt-4">
          <button
            class="btn-primary border-gray"
            data-bs-target="#exampleModalToggle"
            data-bs-toggle="modal"
          >
            <span class="fw-bold">Proceed to Pay</span>
          </button>
          <CreateAccount
            :purchase="purchase"
            @guestPurchase="handleGuestPurchase"
            @continue="showPurchaseSummary"
          ></CreateAccount>
        </div>
      </div>

      <div class="mobile-screen">
        <div class="d-grid mt-4">
          <button
            class="btn btn-primary border-gray"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasBottomA"
            aria-controls="offcanvasBottomA"
          >
            <span class="fw-bold">Proceed to Pay</span>
          </button>
          <CreateAccountMOB
            :purchase="purchase"
            @guestPurchase="handleGuestPurchase"
            @continue="showPurchaseSummary"
          ></CreateAccountMOB>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="purchaseSummary"
    class="step2-wrapper mt-4 d-flex justify-content-center"
  >
    <div class="summary-page">
      <div
        class="d-flex justify-content-between align-items-center mb-3 border-bottom py-3"
      >
        <div class="d-flex align-items-center">
          <div
            class="back-icon mr-2"
            data-bs-target="#exampleModal"
            data-bs-toggle="modal"
          >
            <Iconify icon="ep:arrow-left" />
            <Return @purchaseHistory="showPurchaseSummary"></Return>
          </div>
          <p class="p-text">Purchase Summary</p>
        </div>
      </div>

      <div class="card-wraper border rounded">
        <div
          class="provider px-3 py-1 border-bottom d-flex justify-content-between align-items-center"
        >
          <p class="text-gray p-text">Provider</p>
          <div class="provider-logo rounded p-2">
            {{ purchase.provider }}
          </div>
        </div>
        <div
          class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
        >
          <p class="p-text text-gray">Meter Type</p>
          <p class="p-text">{{ purchase.meter_type }}</p>
        </div>
        <div
          class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
        >
          <p class="p-text text-gray">Meter Number</p>
          <p class="p-text">{{ purchase.meter_no }}</p>
        </div>
        <div
          class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
        >
          <p class="p-text text-gray">Meter Name</p>
          <p class="p-text">{{ apiResponse.Customer_Name }}</p>
        </div>
        <div
          class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
        >
          <p class="text-gray p-text">Phone Number</p>
          <p class="p-text">{{ purchase.phone }}</p>
        </div>
        <div
          class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
        >
          <p class="text-gray p-text">Amount</p>
          <p class="p-text">NGN {{ purchase.amount }}</p>
        </div>
        <div
          class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
        >
          <p class="text-gray p-text">Fee</p>
          <p class="p-text">NGN 100.00</p>
        </div>
      </div>
      <div
        class="d-flex justify-content-between p-3 align-items-center mt-3 border-primary-light rounded"
      >
        <p class="text-gray p-text">Total Amount</p>
        <p class="p-text">NGN {{ formatAmount(totalAmount) }}</p>
      </div>

      <div class="mt-3 bg-gray rounded-pill p-3">
        <div class="form-check form-switch">
          <label class="form-check-label text-gray" for="flexSwitchCheckChecked"
            >Save meter details as a beneficiary</label
          >

          <input
            v-model="saveAsBeneficiary"
            class="form-check-input"
            style="height: 20px"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckChecked"
            value="false"
            @change="saveBeneficiary()"
          />
        </div>
      </div>

      <div class="d-grid mt-4">
        <button
          @click.prevent="Makepayment()"
          class="btn-primary border-gray"
          :disabled="loading"
        >
          <span class="fw-bold">{{
            loading ? "Loading..." : "Proceed to Pay"
          }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-check-input {
  float: right;
}
.form-check-input:checked {
  background-color: #ff6600 !important;
  border-color: #ff6600 !important;
}
.form-switch {
  padding-left: 5px;
}
</style>
