<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { formatDate } from "@/core/utils/helpers";
import OfficeAudit from "@/components/BottomSheet/Audit/OfficeForm/OfficeForm.vue";
import HomeAudit from "@/components/BottomSheet/Audit/HomeForm/AuditHome.vue";
import OfficeResponse from "@/components/BottomSheet/Audit/Response/office.vue";
import HomeResponse from "@/components/BottomSheet/Audit/Response/Home.vue";
import NewUserLogin from "@/components/Modal/NewUserLogin.vue";
import NewLoginMob from "@/components/BottomSheet/CreateAccount/LoginMob.vue";

import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();
const loaded = ref(false);
const officeResponse = ref(false);
const homeResponse = ref(false);
const apiOfficeRes = ref({});
const apiHomeRes = ref({});
const apiOfficeResponse = ref({
  type: "",
  created_at: "",
});

const apiHomeResponse = ref({
  type: "",
  created_at: "",
});

const getOfficeResponse = () => {
  store.commit("setLoader", true);
  store.dispatch("get", `/auditForm/office`).then((resp) => {
    store.commit("setLoader", false);
    officeResponse.value = true;
    loaded.value = true;
    apiOfficeResponse.value = resp.data.data;
    apiOfficeRes.value = apiOfficeResponse.value;
    
  });
};
const getHomeResponse = () => {
  store.commit("setLoader", true);
  store.dispatch("get", `/auditForm/home`).then((resp) => {
    store.commit("setLoader", false);
    homeResponse.value = true;
    loaded.value = true;
    apiHomeResponse.value = resp.data.data;
    apiHomeRes.value = apiHomeResponse.value;
  });
};

const setApiHomeResponse = (item: any) => {
  apiHomeRes.value = item;
};

const setApiOfficeResponse = (item: any) => {
  apiOfficeRes.value = item;
};

onMounted(() => {
  if (store.state.user) {
    getHomeResponse();
    getOfficeResponse();
  }
});
</script>

<template>
  <div class="page-card">
  <div v-if="loaded">
    <div v-if="store.state.user">
       
        <div class="energy-text">
          <div class="back-icon e-icon me-3" @click.prevent="router.go(-1)">
            <Iconify icon="ep:arrow-left" />
          </div>
          <div>
            <p class="fs-5 mb-3 energy">Energy Audit</p>
            <p>
              This questionnaire will help us generate a basic energy needs
              report to design the optimal system to maximize your energy
              reliability and cost savings
            </p>
          </div>
        </div>
        <div class="audit-forms">
          <div class="image-side">
            <p class="text-gray mb-4">
              Please proceed with an option below that best describes the
              location you want to power.
            </p>

            <div class="form-container">
              <div class="row g-4">
                <div class="col-lg-6 col-sm-6">
                  <div class="img-card">
                    <div class="img">
                      <img src="@/assets/images/roof-2.png" alt="" />
                    </div>
                    <div class="mobile-screen">
                      <div
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasBottomD"
                        aria-controls="offcanvasBottomD"
                        class="card-btn btn-primary-light d-flex justify-content-between align-items-center"
                      >
                        <div class="d-flex align-items-center">
                          <div class="red-dot me-2"></div>
                          <p class="text-primary">Home</p>
                        </div>
                        <div>
                          <Iconify class="primary" icon="ep:arrow-right" />
                        </div>
                      </div>
                    </div>
                    <div class="desktop-screen">
                      <div
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight8"
                        aria-controls="offcanvasRight8"
                        class="card-btn btn-primary-light d-flex justify-content-between align-items-center"
                      >
                        <div class="d-flex align-items-center">
                          <div class="red-dot me-2"></div>
                          <p class="text-primary">Home</p>
                        </div>
                        <div>
                          <Iconify class="primary" icon="ep:arrow-right" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                  <div class="img-card">
                    <div class="img">
                      <img src="@/assets/images/roof-3.jpg" alt="" />
                    </div>
                    <div class="mobile-screen">
                      <div
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasBottomE"
                        aria-controls="offcanvasBottomE"
                        class="card-btn btn-primary-light d-flex justify-content-between align-items-center"
                      >
                        <div class="d-flex align-items-center">
                          <div class="red-dot me-2"></div>
                          <p class="text-primary">Office</p>
                        </div>
                        <div>
                          <Iconify class="primary" icon="ep:arrow-right" />
                        </div>
                      </div>
                    </div>
                    <div class="desktop-screen">
                      <div
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight4"
                        aria-controls="offcanvasRight4"
                        class="card-btn btn-primary-light d-flex justify-content-between align-items-center"
                      >
                        <div class="d-flex align-items-center">
                          <div class="red-dot me-2"></div>
                          <p class="text-primary">Office</p>
                        </div>
                        <div>
                          <Iconify class="primary" icon="ep:arrow-right" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="apiHomeResponse || apiOfficeResponse" class="view-request">
            <div
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
              class="d-flex justify-content-between align-items-center bg-gray p-3 rounded"
            >
              <span>View your response history</span>
              <span> <Iconify icon="oui:arrow-down" /> </span>
            </div>
            <div class="collapse" id="collapseExample">
              <div class="card card-body p-0">
                <div
                  class="d-flex justify-content-between align-items-center p-3"
                >
                  <p>Type of space</p>
                  <p class="me-5">Date</p>
                </div>

                <div v-if="homeResponse">
                  <div v-if="apiHomeResponse">
                    <HomeResponse :apiHomeRes="apiHomeRes"></HomeResponse>
                    <div
                      class="d-flex justify-content-between align-items-center border-bottom p-3"
                    >
                      <p>{{ apiHomeResponse.type }}</p>
                      <div class="d-flex align-items-center">
                        <p>{{ formatDate(apiHomeResponse.created_at) }}</p>
                        <div class="desktop-screen">
                          <Iconify
                            @click="setApiHomeResponse(apiHomeResponse)"
                            icon="ri:more-line"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight7"
                            aria-controls="offcanvasRight7"
                            class="ms-2"
                          />
                        </div>
                        <div class="mobile-screen">
                          <Iconify
                            @click="setApiHomeResponse(apiHomeResponse)"
                            icon="oui:arrow-right"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasBottomF"
                            aria-controls="offcanvasBottomF"
                            class="ms-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="officeResponse">
                  <OfficeResponse :apiOfficeRes="apiOfficeRes"></OfficeResponse>
                  <div
                    class="d-flex justify-content-between align-items-center p-3"
                    v-if="apiOfficeResponse"
                  >
                    <p>{{ apiOfficeResponse.type }}</p>
                    <div class="d-flex align-items-center">
                      <p>{{ formatDate(apiOfficeResponse.created_at) }}</p>
                      <div class="desktop-screen">
                        <Iconify
                          @click="setApiOfficeResponse(apiOfficeResponse)"
                          icon="ri:more-line"
                          style="font-weight: 700"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight6"
                          aria-controls="offcanvasRight6"
                          class="ms-2"
                        />
                      </div>
                      <div class="mobile-screen">
                        <Iconify
                          @click="setApiOfficeResponse(apiOfficeResponse)"
                          icon="oui:arrow-right"
                          style="font-weight: 700"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasBottomG"
                          aria-controls="offcanvasBottomG"
                          class="ms-2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <OfficeAudit></OfficeAudit>
        <HomeAudit></HomeAudit>
      </div>
    
  </div>
    <div v-else>
      <div class="d-flex justify-content-center my-5">
        <div class="text-center" style="width: 400px">
          <Iconify icon="lets-icons:flash-light" style="font-size: 28px" />
          <h5 class="my-4">You have not signed in</h5>
          <p class="p-text text-gray mb-4">
            Please create an account or sign in to have access to your account
            settings
          </p>
          <div class="desktop-screen">
            <div class="d-flex align-items-center justify-content-center">
              <button
                data-bs-target="#exampleModalToggleB"
                data-bs-toggle="modal"
                class="btn-primary auth-btn me-3"
              >
                Create an Account
              </button>
              <button
                data-bs-target="#exampleModalToggleC"
                data-bs-toggle="modal"
                class="btn-primary auth-btn"
              >
                Login
              </button>
            </div>
          </div>

          <div class="mobile-screen">
            <div class="d-flex align-items-center justify-content-center">
              <button
                class="btn-primary auth-btn me-3"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottom10"
                aria-controls="offcanvasBottom10"
              >
                Create an Account
              </button>
              <button
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottom11"
                aria-controls="offcanvasBottom11"
                class="btn-primary auth-btn"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>

      <NewUserLogin></NewUserLogin>
      <NewLoginMob></NewLoginMob>
    </div>
  </div>
</template>
