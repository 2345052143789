<script lang="ts" setup>
import EachTransaction from "@/components/BottomSheet/EachTransaction.vue";

import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import NewLoginMob from "@/components/BottomSheet/CreateAccount/LoginMob.vue";

import NewUserLogin from "@/components/Modal/NewUserLogin.vue";
import {
  formatDate,
  formatTime,
  formatDateTime,
  formatAmount,
} from "@/core/utils/helpers";

const store = useStore();
const transactions: any = ref([]);
const transaction: any = ref({});
const loadInfo = ref(false);
const loading = ref(false);

const disabled = ref(false);
const filter = ref({
  page: 1,
  limit: 10,
  from: null,
  to: null,
  name:""
});
const searchTransactions = () => {
  store.commit("setLoader", true);
  store
    .dispatch(
      "get",
      `/purchase?limit=10&from=${filter.value.from}&to=${filter.value.to}`
    )
    .then((resp) => {
      store.commit("setLoader", false);
      transactions.value = resp.data.data;
      transaction.value = transactions.value[0];
      loadInfo.value = true;
    })
    .catch((err) => {
      store.commit("setLoader", false);
    });
};

const getTransactions = () => {
  store.commit("setLoader", true);
  store
    .dispatch(
      "get",
      `/purchase?limit=10&from=${filter.value.from}&to=${filter.value.to}&name=${filter.value.name}`
    )
    .then((resp) => {
      store.commit("setLoader", false);
      transactions.value = resp.data.data;
      transaction.value = transactions.value[0];
      loadInfo.value = true;
      checkTransactionLength();
    })
    .catch((err) => {
      store.commit("setLoader", false);
    });
};

const setTransaction = (item: any) => {
  transaction.value = item;
};

const BuyElectricity = () => {
  window.setTimeout(() => {
    window.location.reload();
  }, 1000);
};

const filterBtn = () => {
  disabled.value = !disabled.value;
};


const showMore = ref(true);

const checkTransactionLength = () => {
  if (transactions.value.length < 10) {
    showMore.value = false;
  } else {
    showMore.value = true;
  }
};

const next = () => {
  if (showMore.value) {
    filter.value.page++;
    getTransactions();
  }
};

const prev = () => {
  filter.value.page--;
  getTransactions();
};
onMounted(() => {
  if (store.state.user) {
    getTransactions();
  }
});
</script>

<template>
  <div v-if="store.state.user">
    <div v-if="loadInfo">
      <div v-if="transactions.length > 0" class="transaction-history mt-4">
        <EachTransaction :transaction="transaction"></EachTransaction>
        <div class="desktop-screen">
          <div
            class="search-field d-flex p-3 justify-content-between align-items-center border rounded"
          >
            <div class="search-input">
              <form @submit.prevent="getTransactions()">
                <input
                  v-model="filter.name"
                  type="search"
                  class="input-field form-control border-0 ps-5"
                  placeholder="Search by name"
                />
                <div class="search-icon">
                  <Iconify
                    icon="iconamoon:search-thin"
                    @click.prevent="getTransactions()"
                  />
                </div>
              </form>
            </div>
            <div
              @click.prevent="filterBtn()"
              class="filter d-flex align-items-center justify-content-center box"
            >
              <iconify icon="bi:filter" style="font-size: 24px" />
              <p class="ms-2">Filter</p>
            </div>
          </div>

          <div v-if="disabled" class="filter-inputs  my-3" >
            <div class="row gx-8">
              <div class="col-lg-4">
                <div class="from">
                  <label class="mb-2 text-gray">Start Date</label>
                  <input
                    v-model="filter.from"
                    type="date"
                    class="form-control search-form  text-gray"
                    id=""
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="from">
                  <label class="mb-2 text-gray">End Date</label>
                  <input
                    v-model="filter.to"
                    type="date"
                    class="form-control search-form text-gray"
                    id=""
                  />
                </div>
              </div>

              <div class="col-lg-2 d-flex align-items-end">
                <div class="d-flex align-items-center searchbtn">
                  <button
                    @click.prevent="searchTransactions()"
                    class="btn-primary px-3 btns me-3"
                  >
                    Search
                  </button>
               
                </div>
              </div>
            </div>
          </div>
          <div class="table border rounded responsive mt-4">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Electricity Provider</th>
                  <th>Meter Type</th>
                  <th>Meter Number</th>
                  <th>Meter Name</th>
                  <th>Amount</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(transact, index) in transactions" :key="index">
                  <td>{{ formatDateTime(transact.created_at) }}</td>
                  <td>{{ transact.provider }}<span class="dot mx-4"></span></td>
                  <td>{{ transact.meter_type }}</td>
                  <td>{{ transact.meter_no }}</td>
                  <td class="text-lowercase">{{ transact.meter_name }}</td>
                  <td>NGN {{ transact.amount }}</td>
                  <td>
                    <div
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRightA"
                      aria-controls="offcanvasRightA"
                      @click="setTransaction(transact)"
                    >
                      <Iconify class="primary" icon="ri:more-line" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
         
        </div>

        <div class="mobile-screen">
          <div class="search-field d-flex align-items-center">
            <div class="search-input me-4">
              <form @submit.prevent="getTransactions()">
                <input
                  v-model="filter.name"
                  type="search"
                  class="input-field form-control ps-5 "
                  placeholder="Search by name"
                />
                <div class="search-icon">
                  <Iconify
                    icon="iconamoon:search-thin"
                    @click.prevent="getTransactions()"
                  />
                </div>
              </form>
            </div>

            <div
              class="filter d-flex align-items-center justify-content-center filter-btn"
            >
              <iconify
                @click.prevent="filterBtn()"
                icon="bi:filter"
                style="font-size: 24px"
              />
              <p class="ms-2 desktop-screen">Filter</p>
            </div>
          </div>
          <div v-if="disabled" class="filter-inputs my-3">
            <div class="row ">
              <div class="col-6 ">
                <label class="mb-2 text-gray">Start Date</label>
                <input
                  v-model="filter.from"
                  type="date"
                  class="form-control search-form  text-gray"
                  id=""
                />
              </div>

              <div class="col-6">
                <label class="mb-2 text-gray">End Date</label>
                <input
                  v-model="filter.to"
                  type="date"
                  class="form-control search-form  text-gray"
                  id=""
                />
              </div>
              
            </div>

            <div class="c-flex searchbtn mt-4">
              <button
                @click.prevent="searchTransactions()"
                class="btn-primary px-3 btns me-3"
              >
                Search
              </button>
           
            </div>
          </div>

          <div class="mt-4">
            <div v-for="(transact, index) in transactions" :key="index">
              <div>
                <div
                  class="d-flex py-4 align-items-center justify-content-between"
                >
                  <div>
                    <p class="p-text text-gray text-capitalize mb-2">
                      {{ transact.meter_name }}
                    </p>
                    <p class="text-gray p-text">{{ transact.provider }}</p>
                  </div>

                  <div class="d-flex align-items-center">
                    <div class="me-3 text-end">
                      <p class="fs-6 mb-2">
                        NGN{{ formatAmount(transact.amount) }}
                      </p>

                      <p class="text-gray p-text">
                        {{ formatDateTime(transact.created_at) }}
                      </p>
                    </div>
                    <div
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasBottomC"
                      aria-controls="offcanvasBottomC"
                      @click="setTransaction(transact)"
                    >
                      <iconify
                        class="primary"
                        icon="ic:sharp-more-vert"
                        style="font-size: 23px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
            class="pagination d-flex mt-5 align-items-center justify-content-between px-4 pb-4"
          >
            <div>
              <div
                v-if="filter.page > 1"
                @click.prevent="prev()"
                class="btn-disabled py-2 px-3 rounded text-primary pointer"
              >
                <span><Iconify icon="bi:arrow-left" class="icons" /></span>
                Previous
              </div>
            </div>
            <div class="page-numb pointer">{{ filter.page }}</div>

            <div>
              <div
                v-if="showMore"
                @click.prevent="next()"
                class="btn-disabled py-2 px-3 rounded text-center text-primary pointer"
              >
                Next
                <span
                  ><iconify icon="bi:arrow-right" style="font-size: 24px" />
                </span>
              </div>
            </div>
          </div>
      </div>
      <div v-else>
        <div class="d-flex justify-content-center mt-5">
          <div class="text-center" style="width: 400px">
            <Iconify icon="icons:flash-light" />
            <h5 class="my-4">No Transaction history</h5>
            <p class="p-text text-gray mb-4">
              Click on the button below to purchase electricity
            </p>
            <div class="d-grid">
              <button
                @click.prevent="BuyElectricity()"
                :disabled="loading"
                class="btn-primary"
              >
                {{ loading ? "Loading..." : "Buy Electricity" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
   
  </div>
  <div v-else>
    <div class="d-flex justify-content-center mt-5">
      <div class="text-center" style="width: 400px">
        <Iconify icon="lets-icons:flash-light" style="font-size: 28px" />
        <h5 class="my-4">You have not signed in</h5>
        <p class="p-text text-gray mb-4">
          Please create an account or sign in to have access to your account
        </p>

        <div class="desktop-screen">
          <div class="d-flex align-items-center justify-content-center">
            <button
              data-bs-target="#exampleModalToggleB"
              data-bs-toggle="modal"
              class="btn-primary auth-btn me-3"
            >
              Create an Account
            </button>
            <button
              data-bs-target="#exampleModalToggleC"
              data-bs-toggle="modal"
              class="btn-primary auth-btn"
            >
              Login
            </button>
          </div>
        </div>

        <div class="mobile-screen">
          <div class="d-flex align-items-center justify-content-center">
            <button
              class="btn-primary auth-btn me-3"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasBottom10"
              aria-controls="offcanvasBottom10"
            >
              Create an Account
            </button>
            <button
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasBottom11"
              aria-controls="offcanvasBottom11"
              class="btn-primary auth-btn"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
    <NewLoginMob></NewLoginMob>
    <NewUserLogin></NewUserLogin>
  </div>

  
</template>

<style lang="scss" scoped></style>
